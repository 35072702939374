<div class="assumption-modal">
  <header>
    <div>
      <h1>Component Card Assumptions</h1>
      <p>
        The following are assumptions used for building up the component by the
        relevant skills network.
      </p>
    </div>
    <p>
      We value your opinion, if you have ideas on how to improve this page,
      <zero-external-link
        href="https://feedback.arup.com/product/zero-suite-zero-veracity-and-analytics"
        >use the appropriate category in our feedback
        section</zero-external-link
      >
    </p>
  </header>
  <div class="component-details">
    <h3>{{ data.assembly.title }}</h3>
  </div>
  <div>
    <p>
      <strong>Mapping variable: </strong>
      {{ data.assembly.mapping_variable }}
    </p>
    <p>
      <strong>Last Updated: </strong>
      {{ data.assembly.date_updated | date: 'mediumDate' }}
    </p>
    <p>
      <strong>Updated By: </strong>
      <mgt-person
        [personQuery]="data.assembly.updated_by"
        person-card="hover"
        show-presence
        view="oneline"
      ></mgt-person>
    </p>
  </div>
  <div class="assumptions">
    <div *ngFor="let assumption of data.assumptions" class="assumption">
      <p>
        <strong>{{ assumption.assumption }}</strong>
      </p>
    </div>
  </div>
</div>
